import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import PortraitImg from "../components/PortraitImg";
import GitHubImg from "../components/GitHubImg";
import LinkedInImg from "../components/LinkedInImg"
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ display: "flex", "flex-direction": "column" }}>
      <div style={{ maxWidth: "556px", marginBottom: "1.45rem", justifyContent: "center" }}>
        <PortraitImg />
      </div>
      <div>
        Jerroyd Moore lives and works with San Francisco.
        He is an alumnus of University of California, Santa Cruz.
      </div>
      <div className="gitHubLink link"><a href="https://github.com/jerroydmoore" target="_blank" rel="noreferrer"><GitHubImg />GitHub</a></div>
      <div className="gitHubLink link"><a href="https://www.linkedin.com/in/jerroydmoore/" target="_blank" rel="noreferrer"><LinkedInImg />LinkedIn</a></div>
      <ul>
        <li>Projects: <a href="https://whoishiring.work" target="_blank" rel="noreferrer">whoishiring.work</a></li>
      </ul>
    </div>
  </Layout>
);

export default IndexPage;
